<template>
  <h2>{{ esuName }}'s Reports</h2>
  <div class="row">

    <div class="col-sm-10">
      <div class="card">
        <div class="card-header">
          <div class="col-sm">
            Aggregate Reports for {{ esuName }}
          </div>

        </div>
        <AggregateReportTemplate :report-data="esuReport"/>
      </div>
    </div>
    <div class="col-sm-2">
      <div class="card">
        <div class="card-header">
          About
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm">
              <p>This is a generalized aggregate report for Project Para Engagement </p>
              <hr/>
              <p>Other content, controls, or links to district reports could go here</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {API_URL} from "../../../../../Constants";
import {onBeforeMount, ref} from "vue";
import AggregateReportTemplate from "@/views/SuperUserViews/Reporting/Aggregate/AggregateReportTemplate";

export default {
  name: "AggregateEsuReport",
  components: {AggregateReportTemplate},
  props: {
    idEsu: Number
  },
  setup(props) {
    const esuName = ref({});
    const esuReport = ref({
      labels: [
        "Total Users",
        "Total District Admins",
        "District Admins Active",
        "District Admins Inactive",
        "Total Instructors",
        "Instructors Active",
        "Instructors Inactive",
        "Total Paras",
        "Para Enabled Count",
        "Para Disabled Count",
      ],
      datasets: [],
      maxCount: 0,
    })

    onBeforeMount(() => {
      getReport();
    })


    async function getReport() {
      let get_uri = API_URL + "/reports/aggregate/byEsu?_idEsu=" + props.idEsu

      // will add authentication once display is normalized
      await axios.get(get_uri)
          .then((result) => {
            esuName.value = result.data.esuName;

            let districts = result.data.districtReports;
            for (let i = 0; i < districts.length; i++) {

              if (districts[i].totalUserCount > esuReport.value.maxCount) {
                esuReport.value.maxCount = districts[i].totalUserCount
              }

              esuReport.value.datasets[i] = {
                label: districts[i].districtName,
                backgroundColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
                data: [
                  districts[i].totalUserCount,
                  districts[i].districtAdminEnrolledCount,
                  districts[i].districtAdminEnabledAccount,
                  districts[i].districtAdminDisabledCount,
                  districts[i].instructorEnrolledCount,
                  districts[i].instructorEnabledCount,
                  districts[i].instructorDisabledCount,
                  districts[i].paraEnrolledCount,
                  districts[i].paraEnabledCount,
                  districts[i].paraDisabledCount
                ]
              };
            }
          })
    }

    return {
      esuReport,
      esuName,
    }
  }
}
</script>

<style scoped>

</style>