<template>
  <div class="col-sm">
    <div class="card-body">
      <Bar
          :chart-options="chartOptions"
          :chart-data="reportData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
      />
    </div>
  </div>
</template>

<script>
import {Bar} from 'vue-chartjs'

import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'
import {onBeforeMount, ref, watch} from "vue";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "AggregateReportTemplate",
  components: {Bar},
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {
      }
    },
    reportData: Object,

  },
  setup(props) {

    const maxCount = ref();

    onBeforeMount(() => {
      maxCount.value = props.reportData.maxCount;
    })


    const chartOptions = ref({
      responsive: true,
      indexAxis: 'y',
      plugins: {
        legend: {
          position: 'right',
          display: true,
        }
      },
      scales: {
        x1: {
          position: 'top',
          display: true,
          min: 0,
          max: maxCount.value
        },
        x2: {
          position: 'bottom',
          display: true,
          min: 0,
          max: maxCount.value
        }
      }
    })

    return {
      chartOptions
    }
  }
}
</script>

<style scoped>

</style>